import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Ringling({ styles, ...props }) {
  const Elements = useComponents()
  const form = React.createRef()
  const content = React.createRef()
  const checkboxes_color = styles.forElement("form_header").color

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div className="formkit-header">
        <div
          className="formkit-bg-color"
          style={{
            backgroundColor: styles.forElement("header_image").backgroundColor
          }}
        />
        <div
          className="formkit-bg-image"
          style={styles.forElement("header_image")}
        />
        <div className="formkit-container formkit-header-content">
          <Elements.Content
            className="formkit-preheader"
            name="preheader"
            defaults={{
              content: "New cookbook"
            }}
          />
          <Elements.Heading
            className="formkit-heading"
            name="heading"
            defaults={{
              content: "United we eat"
            }}
          />
          <Elements.Region className="formkit-subheader" name="subheader">
            <Elements.Content
              defaults={{
                content:
                  "<p>A celebration of cultures, cuisines, people, and places uniting through a love of food, by Chef Jordan Russo.</p>"
              }}
            />
          </Elements.Region>
          <Elements.Button
            className="formkit-signup"
            name="button_link"
            type="button"
            group="button_link"
            onClick={() => form.current.scrollIntoView({ behavior: "smooth" })}
            defaults={{ content: "Download a free recipe" }}
          />
        </div>
        <div className="formkit-dropdown">
          <button
            className="formkit-arrow"
            style={styles.forElement("arrow")}
            onClick={() =>
              content.current.scrollIntoView({ behavior: "smooth" })
            }
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                style={styles.forElement("arrow")}
                d="M16 8L14.59 6.59L9 12.17V0H7V12.17L1.42 6.58L0 8L8 16L16 8Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <Elements.Button
            className="formkit-scroll-down"
            name="arrow_text"
            type="button"
            onClick={() =>
              content.current.scrollIntoView({ behavior: "smooth" })
            }
            defaults={{
              content: "Read more"
            }}
          />
        </div>
      </div>
      <div className="formkit-flex" ref={content}>
        <div>
          <div
            className="formkit-list-section"
            style={styles.forElement("content_1_bg_color")}
          >
            <div className="formkit-container formkit-list-content">
              <Elements.Heading
                className="formkit-content-heading"
                name="content_heading"
                tag="h2"
                defaults={{
                  content:
                    "This book brings cultures and cuisines together through food, combining Italian and Japanese food, as well as sprinkles of other culinary inspirations."
                }}
              />
              <Elements.Region className="formkit-content" name="list">
                <Elements.Content
                  defaults={{
                    content:
                      "<p>INTRODUCING UNITED WE EAT:</p><p>The debut cookbook from professional chef and restauranteur, Jordan Russo.</p>"
                  }}
                />
              </Elements.Region>
            </div>
          </div>
          <div className="formkit-image-section">
            <div
              className="formkit-bg-color formkit-second-bg-color"
              style={{
                backgroundColor: styles.forElement("image_section")
                  .backgroundColor
              }}
            />
            <div
              className="formkit-bg-image formkit-second-image"
              style={styles.forElement("image_section")}
            />
          </div>
        </div>
        <div>
          <div
            className="formkit-main-content"
            style={styles.forElement("content_2_bg_color")}
          >
            <div className="formkit-container formkit-content-main">
              <Elements.Heading
                className="formkit-content-heading"
                group="content_heading"
                name="content_heading_2"
                tag="h2"
                defaults={{
                  content:
                    "Hi, my name is Jordan Russo, and I'm a professional chef and restraunteur. I'm so excited to introduce you to my debut cookbook, United We Eat."
                }}
              />
              <Elements.Region className="formkit-content" name="content">
                <Elements.Content
                  defaults={{
                    content:
                      "<p>I hope that you love reading and using it as much as I loved creating it, and I can't wait to share it with you.</p>"
                  }}
                />
              </Elements.Region>
              <div className="formkit-testimonial-info">
                <div>
                  <Elements.Image
                    className="formkit-avatar"
                    name="avatar"
                    defaults={{
                      src:
                        "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/3oEERbSnwf8tB5go9U3d6c?w=80"
                    }}
                    size={{ w: 56, h: 56 }}
                    aspectRatio={1}
                    shape="circle"
                  />
                </div>
                <div>
                  <Elements.Content
                    className="formkit-testimonial-contact"
                    name="contact"
                    defaults={{
                      content: "<p>Jordan Russo</p>"
                    }}
                  />
                  <Elements.Content
                    className="formkit-testimonial-location"
                    name="location"
                    defaults={{
                      content: "<p>Author</p>"
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="formkit-form-content"
            style={styles.forElement("form_bg_color")}
            ref={form}
          >
            <div className="formkit-form-main">
              <div className="formkit-container formkit-max">
                <Elements.Heading
                  className="formkit-form-heading"
                  name="form_header"
                  tag="h2"
                  defaults={{
                    content: "Download a free recipe from the book"
                  }}
                />
                <Elements.Form>
                  <Elements.Errors />
                  <Elements.CustomFields
                    style={{
                      color: checkboxes_color
                    }}
                  >
                    <Elements.AddFieldButton />
                    <Elements.Button
                      name="submit"
                      group="button"
                      defaults={{ content: "Sign up today" }}
                    />
                  </Elements.CustomFields>
                </Elements.Form>
                <Elements.Content
                  className="formkit-disclaimer"
                  name="disclaimer"
                  defaults={{
                    content:
                      "<p>We respect your privacy. Unsubscribe at any time.</p>"
                  }}
                />
                <Elements.BuiltWith background="form_bg_color" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Elements.LandingPage>
  )
}

export default createTemplate(Ringling, { name: "Ringling" })
